
import { Vue, Component } from 'vue-property-decorator';
import FootBox from '@/components/common/footBox.vue';
@Component({
    components: {
        FootBox,
    },
})
export default class Home extends Vue {
    cardList = [
        {
            backUrl: require('@/assets/caseImg/card-small-b1.png'),
            iconUrl: require('@/assets/caseImg/card-i1.png'),
            title: '成都公交客服全面升级：全天候智能体验提升计划',
            content:
                '智能科技引领现代化服务新风尚，爱因智能与成都公交携手，描绘出一幅全面数字化客服的未来图景。从解答疑惑的第一声"您好"，到挂电话的一句"感谢您的来电"，每个环节都焕发着科技的精粹。对话无界，服务无边，一场关于便利和温馨的服务革命，正在成都公交稳步展开。',
            tags: ['智能客服', '智能外呼', '智能坐席助手', '智能质检'],
        },
        {
            backUrl: require('@/assets/caseImg/card-small-b2.png'),
            iconUrl: require('@/assets/caseImg/card-i2.png'),
            title: '无限极创新升级：全新智能营销系统驱动',
            content:
                '在知识的璀璨星河中，爱因智能点亮了智慧的灯塔。一段简单的对话，一次深入的交互，数字员工和无限极的知识库联手，让销售团队轻松驾驭产品海洋。智能小极的虚拟灵动，不仅提升了学习效率，更成就了数字营销的新里程。',
            tags: ['数字员工 (内部智能客服) ', '虚拟形象定制', '智能外呼'],
        },
        {
            backUrl: require('@/assets/caseImg/card-small-b3.png'),
            iconUrl: require('@/assets/caseImg/card-i3.png'),
            title: '智能客服与营销系统全渠道助力：美赞臣品牌赋能计划',
            content:
                '在数字化浪潮引领之下，爱因智能引领客服服务革新之路。推出与美赞臣咨询渠道无缝整合的智能平台，彻底重塑顾客互动新格局。专业与科技的交汇，催生出革命性的自助服务体验——不仅高效而且个性化，这种独特融合正提升品牌忠诚度并推动运营卓越。',
            tags: ['智能客服', '智能外呼', '智能质检', '智能营销小程序'],
        },
        {
            backUrl: require('@/assets/caseImg/card-small-b4.png'),
            iconUrl: require('@/assets/caseImg/card-i4.png'),
            title: '慕思家居：打造卓越客服体验，树立客户满意度新标杆',
            content:
                '当舒适与未来主义融合，慕思家具领航家居的新时代。在每一线束光下，都是向着极致用户体验的坚定步伐。促成慕思与用户之间心灵的契合，不仅是一张床垫，更是慕思梦想的启航——这就是超越98.5%客户满意度背后的答案。慕思选择爱因智能同航，共启智慧客服新纪元',
            tags: ['智能客服', '智能外呼'],
        },
        {
            backUrl: require('@/assets/caseImg/card-small-b5.png'),
            iconUrl: require('@/assets/caseImg/card-i5.png'),
            title: 'HI，我是南越王博物馆虚拟人‘小羽’',
            content:
                '南越王博物馆，借力科技，让历史“动起来”。南越王博物馆联合爱因智能，打造二次元虚拟人‘小羽’，作为智能客服，回答游客咨询，增强交互体验。‘小羽’在第六届“绽放杯”5G应用征集大赛中荣获全国一等奖，为文旅AI应用设立新标杆。',

            tags: ['智能客服', '虚拟人'],
        },
        {
            backUrl: require('@/assets/caseImg/card-small-b6.png'),
            iconUrl: require('@/assets/caseImg/card-i6.png'),
            title: '老板电器引领科技革新：打造智慧服务新境界',
            content:
                '面临市场需求日益增长的挑战，老板电器选择拥抱科技，联合爱因智能探索智能客服的应用。在无人化环境中，成功实现了10多种家电预约送货、安装等服务的自动化，大幅节省了人力成本。这是老板电器在家电行业数智化转型道路上的又一次坚实的步伐。',
            tags: ['智能客服'],
        },
        {
            backUrl: require('@/assets/caseImg/card-small-b7.png'),
            iconUrl: require('@/assets/caseImg/card-i7.png'),
            title: '长隆：持续创新，服务升级无止境',
            content:
                '长隆集团面临游客咨询量大的挑战，与爱因智能联手打造智能客服系统。该系统覆盖多渠道，实现无人化处理订单，建立客服知识体系，极大提升了工作效率。这一改革不仅满足了游客需求，也为企业服务提效探索了新途径。',
            tags: ['智能客服', '知识库'],
        },
        {
            backUrl: require('@/assets/caseImg/card-small-b8.png'),
            iconUrl: require('@/assets/caseImg/card-i8.png'),
            title: '大模型，小结大改变',
            content:
                '全球消费品巨头宝洁与我司自2017年起深度合作，智能客服覆盖了广泛的业务线。随着大模型的兴起，我们探索了其在现有业务中的潜力，尤其在客服通话小结业务中发现了新的可能。爱因为宝洁专门打造了专属的大模型助手应用，在通话中抓取信息生成小结，提升了人工客服效率，推动了客服业务的进步。',
            tags: ['大模型助手'],
        },
    ];

    showDetail = false;
    currentIndex = 0;
    detailList = [
        {
            backUrl: require('@/assets/caseImg/card-b1.jpg'),
            iconUrl: require('@/assets/caseImg/card-t1.png'),
            title: '全天候智能体验提升计划',
            intro: `智能科技引领现代化服务新风尚，爱因智能与成都公交携手，描绘出一幅全面数字化客服的未来图景。从解答疑惑的第一声"您好"，到挂电话的一句"感谢您的来电"，每个环节都焕发着科技的精粹。对话无界，服务无边，一场关于便利和温馨的服务革命，正在成都公交稳步展开。`,
            needs: [
                `面对乘客咨询需求的增长，成都公交的传统人工客服体系不足以应对。爱因智能依据其业务流程和现有客服体系，打造了一套全流程的智能客服解决方案。该方案全面升级了成都公交的客服体系，标志着其客服服务迈入智能化新纪元。`,
            ],
            solution: [
                {
                    text: '爱因智能公司为成都公交定制的全流程智能客服解决方案，在通话前、中、后各环节实现全面赋能。具体包括：',
                },
                {
                    isList: true,
                    text: '通话前：智能在线及热线客服对乘客咨询进行有效分流。',
                },
                {
                    isList: true,
                    text: '通话中：坐席助手通过实时文本转写协助人工客服高效填写工单。',
                },
                {
                    isList: true,
                    text: '通话后：建立客服评价体系，对录音进行全量智能质检，并自动执行满意度回访。',
                },
                {
                    noIndent: true,
                    text: '实施效果显著，主要成就包括：',
                },
                {
                    text: '1.实现全天候7x24小时热线服务，确保100%接通率，大幅提升用户体验。',
                },
                {
                    text: '2.坐席助手准确辅助记录乘客需求，提高服务效率。',
                },
                {
                    text: '3.建立成都公交客服评价体系，全量快速质检人工客服通话录音，有效提升客服团队服务质量。',
                },
                {
                    text: '4.利用智能外呼及时进行客户满意度跟踪。',
                },
                {
                    text: '这套方案极大提高了成都公交的服务品质和工作效率。',
                },
            ],
            result: `2023年10月底成都公交智能客服正式上线使用，上线2周内，智能客服就承接了30%的业务量，包含公交线路查询、公交卡问题咨询、司机投诉表扬等等。随着不断的上线优化、相应语料的扩充，智能客服将承接70%-80%的咨询量，上线以来乘客反馈再也不会出现热线无人接听的状态，不论在什么时间段，只要智能客服能够回答的问题都能及时得到回应，大大提升了成都市民出行满意度。`,
            production: '智能客服、智能外呼、智能坐席助手、智能质检',
        },
        {
            backUrl: require('@/assets/caseImg/card-b2.png'),
            iconUrl: require('@/assets/caseImg/card-t2.png'),
            title: '全新智能营销系统驱动',
            intro: `在知识的璀璨星河中，爱因智能点亮了智慧的灯塔。一段简单的对话，一次深入的交互，数字员工和无限极的知识库联手，让销售团队轻松驾驭产品海洋。智能小极的虚拟灵动，不仅提升了学习效率，更成就了数字营销的新里程。`,
            needs: [
                `作为香港健康品行业的领先品牌，无限极拥有数万种产品，这给一线销售人员在产品学习和推广上带来了挑战；同时，由于其直销模式的限制，无限极在营销手段和效果上也面临困境。针对这些问题，爱因为无限极定制了有效的解决方案。`,
            ],
            solution: [
                {
                    color: '#e3bb39',
                    title: '数字员工',
                    text: [
                        `为简化产品知识学习和提高销售效率，爱因在后端创建了一个包含多种形式材料（文字、语音、图片、视频）的无限极产品知识库，前端搭建智能语音机器人--数字员工。销售人员可通过与该数字员工互动快速获取产品信息。`,
                        `此外，我们还设计了“智能小极”这一虚拟形象，使销售团队可以通过各种渠道轻松与之交互，显著提升了学习效率。该方案在无限极内部赢得了最佳智能体验奖和年度总裁优秀项目奖项的认可。`,
                    ],
                },
                {
                    color: '#e3bb39',
                    title: '智能营销需求&解决方案',
                    text: [
                        '结合AI智能外呼技术，我们为无限极打造了新型营销推广模式。在618、双11等大促期间，该模式以低成本且更高效的手段从多角度触达目标客户。AI算法能够识别各层级意向客户，并通过短信链接引导他们至小程序或直播页面完成购买。',
                        '以2022年618活动为例，智能外呼的ROI高达14，有效推动了无限极的数字化营销进程。',
                    ],
                },
            ],
            result: '',
            production: '数字员工（内部智能客服）、虚拟形象定制、智能外呼',
        },
        {
            backUrl: require('@/assets/caseImg/card-b3.png'),
            iconUrl: require('@/assets/caseImg/card-t3.png'),
            title: '美赞臣品牌赋能计划',
            intro: `在数字化浪潮引领之下，爱因智能引领客服服务革新之路。推出与美赞臣咨询渠道无缝整合的智能平台，彻底重塑顾客互动新格局。专业与科技的交汇，催生出革命性的自助服务体验——不仅高效而且个性化，这种独特融合正提升品牌忠诚度并推动运营卓越。`,
            needs: [
                '美赞臣作为全球著名的婴幼儿营养品牌，在中国凭借本土化策略和高品质产品多年稳居奶粉行业前列，深受国内父母青睐。',
                '随着市场对母婴产品服务质量要求的提升，美赞臣原有的传统客服系统已难以满足用户对于及时性、高效性和一致性的高需求，同时，提升营销触达率亦是品牌面临的挑战。',
                '针对这些痛点，爱因依托AI技术，为美赞臣定制了一套数字化赋能方案，旨在全面优化母婴用户体验、提升品牌互动效率。',
            ],
            solution: [
                {
                    color: '#a3f1ff',
                    title: '用户触点全覆盖--智能客服',
                    text: [
                        '爱因打造的智能客服平台整合了美赞臣所有咨询渠道，包括热线、微信和小程序，并与其CRM系统对接，实现了多渠道自助服务，涵盖积分、活动、产品和物流等高频问题查询。客服系统上线后提供全天候服务，客户咨询效率提升了67%。随着业务深化和语料完善，智能客服开始接管部分专业营养师的咨询工作。',
                    ],
                },
                {
                    color: '#a3f1ff',
                    title: 'VOC（Voice of Customer）--智能质检',
                    text: [
                        '爱因针对美赞臣人工质检难、效果差的问题提出了智能质检方案。通过构建语音质检模型、上线智能质检平台，实现对全量语音自动质检。',
                        '该方案从客服角度建立了客服服务评价体系，同时进行基于用户视角的舆情监控与产品热度分析，并深入分析客户数据以促进业务优化。',
                    ],
                },
                {
                    color: '#a3f1ff',
                    title: '智能营销多重触达--智能外呼、智能营销小程序',
                    text: [
                        '在奶粉行业中，客户在宝宝不同成长阶段常面临品牌转换的风险，鉴于用户基数庞大，传统人工维护难以全面覆盖。面对庞大用户群和有限的人力资源挑战，美赞臣借助爱因的智能外呼产品进行高效的客户管理。',
                        '爱因的智能外呼系统可以通过客户画像制定个性化的外呼脚本，对接CRM系统获取用户号码并自动联系客户进行维护、促销、信息收集等动作，有效激发用户复购。',
                    ],
                },
            ],
            result: '',
            production: '智能客服、智能外呼、智能质检、智能营销小程序',
        },
        {
            backUrl: require('@/assets/caseImg/card-b4.png'),
            iconUrl: require('@/assets/caseImg/card-t4.png'),
            title: '打造卓越客服体验，树立客户满意度新标杆',
            intro: `当舒适与未来主义融合，慕思家具领航家居的新时代。在每一线束光下，都是向着极致用户体验的坚定步伐。促成慕思与用户之间心灵的契合，不仅是一张床垫，更是慕思梦想的启航——这就是超越98.5%客户满意度背后的答案。慕思选择爱因智能同航，共启智慧客服新纪元。`,
            needs: [
                `慕思家具，国内床垫行业的领先品牌，凭借98.5%以上的高客户满意度在消费者中树立了卓越的口碑。为进一步提升服务体验，慕思选择与爱因智能合作，摒弃传统人工客服，推出全链路智能客服及外呼系统。`,
            ],
            solution: [
                {
                    text: '随着业务量增长，慕思家具原有的人工客服难以满足用户咨询需求，导致热线等待时间长、客户满意度下降。智能客服系统上线后，不仅大大缩短了热线等待时间，还实现了高达97%的夜间咨询和报修问题解决率，确保了全天候服务，从而全面提升客户满意度。',
                },
                {
                    text: '在用户关怀方面，面对多样的业务场景和有限的人力资源，慕思采用了智能外呼机器人。这些机器人能够自动执行外呼任务，快速完成满意度回访和节日礼物确认等工作，提高了数据回流效率。原本需要一个月才能完成的工作量，现在一周内即可处理完毕，极大提高了客服团队的工作效率。',
                },
            ],
            result: ``,
            production: '智能客服、智能外呼',
        },
        {
            backUrl: require('@/assets/caseImg/card-b5.png'),
            iconUrl: require('@/assets/caseImg/card-t5.png'),
            title: 'HI，我是南越王博物馆虚拟人‘小羽’',
            intro: `南越王博物馆，借力科技，让历史“动起来”。南越王博物馆联合爱因智能，打造二次元虚拟人‘小羽’，作为智能客服，回答游客咨询，增强交互体验。‘小羽’在第六届“绽放杯”5G应用征集大赛中荣获全国一等奖，为文旅AI应用设立新标杆。`,
            needs: [
                `南越王博物馆作为国家一级博物馆，致力于提高公众对历史文化的兴趣和亲近感。为了给历史注入现代“科技感”，并顺应时代的进步，南越王博物馆联合爱因智能，利用先进的虚拟人技术，共同创造了虚拟形象--“小羽”，以新颖的方式吸引观众，增强互动体验，也为市民了解历史增添了一个新窗口。`,
            ],
            solution: [
                {
                    text: '本项目构建了以二次元“小羽”为形象的虚拟人智能客服。“小羽”支持一系列咨询问题的回复，例如博物馆闭馆时间、南越王介绍、南越王博物馆地址等等，在用户与“小羽”进行语音交互的过程中，“小羽”可以做出相应动作，使用户交互体验更佳，更能感受沟通的温度。',
                },
                {
                    text: '在第六届“绽放杯”5G应用征集大赛5G+文化旅游专题赛决赛中，爱因智能与南越王携手打造的虚拟人“小羽”斩获了全国一等奖，打造了文旅行业AI应用新标杆。',
                },
            ],
            result: ``,
            production: '智能客服、虚拟人',
        },
        {
            backUrl: require('@/assets/caseImg/card-b6.png'),
            iconUrl: require('@/assets/caseImg/card-t6.png'),
            title: '打造智慧服务新境界',
            intro: `面临市场需求日益增长的挑战，老板电器选择拥抱科技，联合爱因智能探索智能客服的应用。在无人化环境中，成功实现了10多种家电预约送货、安装等服务的自动化，大幅节省了人力成本。这是老板电器在家电行业数智化转型道路上的又一次坚实的步伐。`,
            needs: [
                `老板电器，作为国内领先的中高端厨电品牌，拥有广泛的产品线和全国用户基础。顾客在购买其产品后，会拨打其服务热线电话完成预约上门送货、安装等事宜。随着家电行业市场扩增，购买家电的用户也越来越多，面对日益增长的用户报装服务热线量，加之竞争对手也上线了智能客服，老板电器在进行多家供应商对比后，选择与爱因智能合作，引入智能客服技术以优化顾客服务体验。`,
            ],
            solution: [
                {
                    text: '通过深度剖析并改进老板电器热线服务流程、对接相关工单业务系统，智能客服成功实现了超过 10 种家电产品的预约送货、安装等服务工单自动化处埋。这一创新大幅削减了人力开支，提升了效率。作为家电行业数字化转型的先锋，老板电器此次智能客服系统的引入，不仅巩固了其行业领导者的地位，也为其数字化转型之路增添了坚实的一笔。',
                },
            ],
            result: ``,
            production: '智能客服',
        },
        {
            backUrl: require('@/assets/caseImg/card-b7.png'),
            iconUrl: require('@/assets/caseImg/card-t7.png'),
            title: '持续创新，服务升级无止境',
            intro: `长隆集团面临游客咨询量大的挑战，与爱因智能联手打造智能客服系统。该系统覆盖多渠道，实现无人化处理订单，建立客服知识体系，极大提升了工作效率。这一改革不仅满足了游客需求，也为企业服务提效探索了新途径。`,
            needs: [
                `长隆作为国内文旅领军企业，运营着广州长隆及珠海长隆两大顶级度假区，年均接待游客超4000万。随着其业务的不断扩展和国民旅游热情的持续上升，旗下乐园、酒店的用户咨询量暴增，长隆也增加了多个C端咨询渠道，包含公众号、小程序、app等。而为了应对激增的用户咨询需求（购票、退票、日常咨询、投诉等等），长隆刚开始也尝试了第三方智能客服的产品，但效果欠佳，其人工客服团队也难以应对激增的游客咨询，因此提升售前、售中、售后服务的接待效率成为了长隆亟需优化的问题。`,
            ],
            solution: [
                {
                    text: '2023年12月，长隆携手爱因智能打造的多渠道多园区高并发的智能客服系统正式上线使用。',
                },
                {
                    text: '该智能客服系统的上线使用从以下个方面提升了长隆客服体系的接待效率：',
                },
                {
                    text: '1.多渠道融合接入：系统整合了app、小程序、公众号和PC端等多种咨询渠道，多渠道融合接入统一接待，为游客提供无缝连接的服务体验。',
                },
                {
                    text: '2.广泛覆盖业务范围：智能客服机器人能够处理涉及长隆集团旗下八大主题乐园和七家酒店的咨询问题，确保游客各类咨询得到及时响应。',
                },
                {
                    text: '3.高效工单管理：通过对接长隆的客户关系管理（CRM）系统，智能客服能够在交互过程中自动办理订单的退改、投诉或表扬工单生成等流程性事务，有效提升服务效率。',
                },
                {
                    text: '4.提升工作效率：智能客服的引入极大地提高了长隆客服团队的工作效率，使得客服人员能够将更多精力投入到精细化的服务工作中，而非繁琐的日常事务处理。',
                },
                {
                    text: '5.构建知识体系：爱因智能协助长隆构建了结构化的客服知识体系，将之前分散且标准不一的知识进行了整理和优化，确保了知识的高效复用和客服工作的一致性。',
                },
                {
                    text: '6.数据价值沉淀：系统的运行有效地积累了宝贵的数据资源，为长隆集团未来的决策提供了数据支持，增强了业务的可持续发展能力。',
                },
                {
                    text: '7.显著的服务提升：自系统上线以来，长隆已经实现了AI独立接待率的翻倍提升，服务首次响应时间的大幅缩短，客户满意度的明显提高。',
                },
                {
                    text: '长隆集团与爱因智能的合作正在不断深化，爱因智能将持续提供更多的人工智能能力，助力长隆集团在业务上实现更大的突破和创新。',
                },
            ],
            result: ``,
            production: '智能客服、知识库',
        },
        {
            backUrl: require('@/assets/caseImg/card-b8.png'),
            iconUrl: require('@/assets/caseImg/card-t8.png'),
            title: '大模型，小结大改变',
            intro: `全球消费品巨头宝洁与我司自2017年起深度合作，智能客服覆盖了广泛的业务线。随着大模型的兴起，我们探索了其在现有业务中的潜力，尤其在客服通话小结业务中发现了新的可能。爱因为宝洁专门打造了专属的大模型助手应用，在通话中抓取信息生成小结，提升了人工客服效率，推动了客服业务的进步。`,
            needs: [
                '宝洁公司，作为全球知名的日用消费品企业之一，自2017年起与我司建立了紧密的合作关系。我们的智能客服产品已被广泛应用于宝洁集团的11个产品线，覆盖了包括商品专柜查询、产品真伪验证以及维修服务申请在内的多项客户服务内容。',
                '随着先进的大模型技术兴起，智能客服的服务深度在不断延伸，爱因与宝洁集团也在思考如何将大模型的能力赋能到现有的业务流程，以期提升工作效率和效果。经过一系列的前期探索与优化，宝洁集团和爱因智能在人工客服通话小结业务中找到了一条大模型赋能客服业务的可行之路。',
                '具体而言，宝洁的售后客服人员在处理消费者的咨询电话后，需撰写详细的通话小结。这一过程面临以下挑战：',
                '1.通话内容复杂繁琐，客服难以完整记忆所有细节。',
                '2.客服撰写通话小结的时间几乎与通话时间等长，耗费大量人力资源。',
                '3.鉴于宝洁拥有上万种SKU，不同客服对产品的熟悉程度不一，导致通话小结的质量参差不齐。',
                '大模型凭借其卓越的内容理解和总结能力，为以上问题提供了理想的解决思路。',
            ],
            solution: [
                {
                    text: '通过深入分析宝洁集团的小结业务流程及其丰富的产品库（SKU），爱因智能基于母公司--科大讯飞自主研发的国产自然语言处理大模型，打造了专属于宝洁集团的大模型助手应用。',
                },
                {
                    text: '大模型助手能够在客服与消费者通话时实时转写语音内容，并在通话结束后，通过接口将录音文本回传给大模型。随后，大模型利用定制化的提示词（prompt）迅速生成规范化的通话小结。此外，大模型还运用多重定制化的提示，将通话中提及的宝洁产品信息与宝洁集团的SKU数据库进行匹配，从而准确输出相关产品详情，以便人工客服后续跟进。',
                },
                {
                    text: '大模型助手的引入显著提升了通话小结的标准化程度和准确性，并能有效提取完整的产品信息，为宝洁后续的售后服务提供有力支持。对于人工客服而言，他们无需在通话后人工梳理填写通话小结，可以将更多时间投入到接听消费者的电话上，极大地提高了工作效率。这一改进不仅优化了客服流程，也增强了客户服务体验。',
                },
            ],
            result: ``,
            production: '大模型助手',
        },
    ];
    detailData = {};
    mounted() {
        window.addEventListener('keydown', this.listenEsc);
        for (let item of this.detailList) {
            let image = new Image();
            image.onload = () => {};
            image.src = item.backUrl;
        }
    }
    beforeDestroy() {
        window.removeEventListener('keydown', this.listenEsc);
    }
    listenEsc(e) {
        if (e.key === 'Escape') {
            this.closeDetail();
        }
    }
    closeDetail() {
        document.querySelector('body').style.overflow = 'auto';
        this.showDetail = false;
    }
    checkDetail(index) {
        document.querySelector('body').style.overflow = 'hidden';
        this.showDetail = true;
        this.currentIndex = index;
        this.detailData = this.detailList[index];
        this.$nextTick(() => {
            document.querySelector('.right-wrapper').scrollTo(0, 0);
        });
    }
    pre() {
        if (this.currentIndex === 0) {
            this.currentIndex = this.detailList.length - 1;
            this.checkDetail(this.currentIndex);
        } else {
            this.checkDetail(this.currentIndex - 1);
        }
    }
    next() {
        if (this.currentIndex === this.detailList.length - 1) {
            this.currentIndex = 0;
            this.checkDetail(this.currentIndex);
        } else {
            this.checkDetail(this.currentIndex + 1);
        }
    }
}
